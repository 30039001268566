import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "page-container relative" }
const _hoisted_3 = {
  class: "absolute -top-0 right-12 text-gray-400 z-100",
  "data-aos": "fade-left",
  "data-aos-duration": "1000"
}
const _hoisted_4 = { class: "flex justify-center min-h-screen lg:px-8" }
const _hoisted_5 = { class: "sm:mx-auto w-full max-w-5xl py-10" }
const _hoisted_6 = { class: "mb-10 mx-48" }
const _hoisted_7 = {
  class: "bg-white px-10 py-4 shadow-xl relative overflow-hidden",
  style: { borderRadius: '16px' }
}
const _hoisted_8 = { class: "flex justify-center items-center py-8 gap-2" }
const _hoisted_9 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return ($setup.isLoadingCreateTenant)
    ? (_openBlock(), _createBlock($setup["BaseSpinner"], {
        key: 0,
        "full-screen": ""
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["TheHeader"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["TheClock"])
          ]),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createVNode(_component_el_dialog, {
          modelValue: $setup.isDialogVisible,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isDialogVisible) = $event)),
          width: "50%",
          "show-close": false,
          "close-on-click-modal": false
        }, {
          default: _withCtx(() => [
            _createVNode($setup["TenantTermCondition"], { onIsClose: $setup.handleCloseTC })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode($setup["TheStep"], {
                currentStep: $setup.currentStep,
                steps: $setup.accountRegistrationProgress
              }, null, 8, ["currentStep", "steps"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode($setup["TenantRegistrationForm"], {
                isFormValid: $setup.isFormValid,
                onFormValid: $setup.handleFormValid
              }, null, 8, ["isFormValid"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                class: "btn btn--green-primary btn-gray-700 px-10 py-3 disabled:bg-gray-400",
                disabled: !$setup.isFormValid,
                onClick: _withModifiers($setup.handleSubmit, ["prevent"])
              }, " Submit ", 8, _hoisted_9)
            ])
          ])
        ]),
        _createVNode($setup["TheFooter"], { class: "page-footer" })
      ]))
}